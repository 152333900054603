<template>
  <div class="mt-2">
    <viewer :images="images" @inited="inited" class="viewer" ref="viewer">
      <img v-for="(src, i) in images" :src="src" :key="i" class="image" />
    </viewer>
    <v-row class="mx-2">
      <v-col cols="12" sm="2">
        <BarraIzquierda />
      </v-col>
      <v-col cols="12" sm="8">
        <DeclaracionMedicamentos />
      </v-col>
      <!-- <v-col cols="12" sm="8">
         
        </v-col> -->
      <v-col cols="12" sm="2">
        <BarraDerecha />
      </v-col>
    </v-row>
    <!--  <DialogSubirDescansoMedico />
      <DialogConsentimiento />
      <DialogSubirEvidencias />
      <DialogSubirEvidenciasMedicamento />
      <DialogPdfConsentimiento ref="dialogPdf" /> -->
    <v-tour name="myTour" :steps="steps">
      <template v-slot="tour">
        <transition name="fade">
          <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
            :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
            <template v-slot:actions >
              <v-btn v-if="tour.currentStep === 0" color="orange" variant="tonal" class="white--text" @click="tour.skip" size="x-small">
                CERRAR TUTORIAL
              </v-btn>
              <v-btn v-if="tour.currentStep === 1" color="orange" variant="tonal" class="white--text" @click="tour.skip" size="x-small">
                CERRAR TUTORIAL
              </v-btn>
            </template>           
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>
<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
/* import DialogSubirDescansoMedico from '../components/DialogSubirDescansoMedico'
import DialogConsentimiento from '../components/DialogConsentimiento'
import DialogSubirEvidencias from '../components/DialogSubirEvidencias'
import DialogSubirEvidenciasMedicamento from '../components/DialogSubirEvidenciasMedicamento'
import DialogPdfConsentimiento from '@/components/DialogPdfConsentimiento.vue' */
import BarraDerecha from '@/components/BarraDerecha.vue'
import BarraIzquierda from '@/components/BarraIzquierda.vue'
import DeclaracionMedicamentos from '@/components/DeclaracionMedicamentos.vue'
/* import AtencionDescansoMedicos from '@/components/AtencionDescansoMedicos.vue' */

export default {
  name: 'FichasView',
  components: {
    /*  DialogSubirDescansoMedico,
     DialogConsentimiento,
     DialogSubirEvidencias,
     DialogSubirEvidenciasMedicamento,
     DialogPdfConsentimiento, */
    Viewer,
    BarraDerecha,
    BarraIzquierda,
    DeclaracionMedicamentos,
    /* AtencionDescansoMedicos */
  },
  data: () => ({
    myOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: 'Saltar tutorial',
        buttonPrevious: 'Anterior',
        buttonNext: 'Siguiente',
        buttonStop: 'Finalizar'
      }
    },
    headers: [
      { text: 'Fecha', align: 'center', sortable: false, value: 'created_at' },
      { text: 'Descanso Medico', align: 'center', value: 'descanso_medico' },
      //{ text: 'Consentimiento', align: 'center', value: 'consentimiento' },
      { text: 'Evidencias', align: 'center', value: 'evidencia' },
      { text: 'Estado', align: 'center', value: 'estado' },
    ],
    images: []
  }),
  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },
    agregarAtencion() {
      const data = {
        id_paciente: this.$store.state.paciente.id
      }
      this.$store.dispatch('storeAtencion', data)
    },
    agregarMedicamento() {
      const data = {
        id_paciente: this.$store.state.paciente.idpacientes
      }
      this.$store.dispatch('storeMedicamento', data)
    },
    async listarAtencion(id) {
      await this.$store.dispatch('fetchAtencion', id)
      console.log(this.$store.state.data)
    },
    abrirDialogSubirDescansoMedico(id) {
      this.$store.commit('SET_ATENCION_ID', id)
      this.$store.commit('SET_DIALOG_SUBIR_DESCANSO_MEDICO', true)
    },
    abrirDialogConsentimiento(id) {
      this.$store.commit('SET_ATENCION_ID', id)
      this.$store.commit('SET_DIALOG_CONSENTIMIENTO', true)
    },
    abrirDialogEvidencias(id) {
      this.$store.commit('SET_ATENCION_MEDICAMENTO_ID', id)
      this.$store.commit('SET_DIALOG_EVIDENCIAS', true)
    },
    abrirDialogPdfConsentimiento(id) {
      this.$store.commit('SET_CONSENTIMIENTO_ID', id)
      this.$refs.dialogPdf.onShowPdf(id)
      this.$store.commit('SET_DIALOG_PDF_CONSENTIMIENTO', true)
    },
    verFotos(fotos) {
      this.images = [];
      fotos.forEach(foto => {
        this.images.push(process.env.VUE_APP_API_URL + '/api/showdm/' + foto.ruta);
      });
      this.$viewer.show();
    },
    nuevaAtencion() {
      const data = {
        id_paciente: this.$store.state.paciente.id
      }
      this.$store.dispatch('storeAtencion', data)
    },
  },
  computed: {
    atenciones() {
      return this.$store.state.data
    },
    steps() {
      return this.$store.state.steps
    }
  },
  async created() {
    await this.listarAtencion(this.$store.state.paciente.id)
  },
  mounted() {
    this.$tours['myTour'].start()
  }
}
</script>
<style>
.image {
  display: none;
}
</style>